.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.pd5 {
  padding: 5px;
}
/* login form */

.orders {
  display: flex;
  justify-content: space-around;
  padding: 0 2.5rem;
}
.orders li {
  padding: 0 1.875rem;
  position: relative;
  width: 25%;list-style-type: none;text-align: left;
}
.orders li h5{font-size: 16px;font-weight: 600;margin: 0;}
.orders li a {
  background: #fff;
  border: .25rem solid #9b9b9b;
  border-radius: 5.875rem;
  display: block;
  height: 5.875rem;
  line-height: 5.375rem;
  margin-bottom: .9375rem;
  position: relative;
  text-align: center;
  width: 5.875rem;
  z-index: 1;
}
.orders .active a {
  background: #2e9a3f;
  /* background: var(--primary); */
  border-color: #2e9a3f;
  /* border-color: var(--primary); */
}
.orders li span{font-size: 13px;}
.orders .process a {
  border-color: #2e9a3f;
  /* border-color: var(--primary); */
}
.orders .process a svg{color: #2e9a3f;}
.orders li:after {
  background: #9b9b9b;
  content: "";
  height: .125rem;
  left: 1.875rem;
  position: absolute;
  top: 3.125rem;
  width: 100%;
}
.orders .active:after {
  background: #2e9a3f;
  /* background: var(--primary); */
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 1rem;
  padding: 0;    font-size: 1.1875rem;
}
.breadcrumb-item.active {
  color: #6c757d;
}
.breadcrumb .breadcrumb-item+.breadcrumb-item:before, .breadcrumb .breadcrumb-item.active a {
  color: #2e9a3f;   
  /* color: var(--primary); */
}
.btn-link.active, .btn-link:focus, .btn-link:hover, a, a.active, a:focus, a:hover {
  text-decoration: none;
}
.breadcrumb a {
  color: #899bbd;
  transition: 0.3s; text-decoration: none;font-weight: normal;
}
.order-media img {
  border-radius: 1.25rem;
  height: 5.6875rem;
  margin-bottom: .5rem;
  width: 5.6875rem;
}
.imfo i {
  background: #2e9a3f;
  /* background: var(--secondary); */
  border-radius: 2.625rem;
  color: #fff;
  height: 2.625rem;
  line-height: 2.625rem;
  text-align: center;
  width: 2.625rem;
}
.text-small{font-size: 12px;color: #6c757d;}
.delivery-guy img {
  border-radius: 1.25rem;
  height: 5.3125rem;
  width: 5.3125rem;
}
.text-left{text-align: left;}
.t_title{    font-size: 16px;
  font-weight: 600;}
  .delivery-contact li{list-style-type: none;}
.delivery-contact li a {
    background: #2e9a3f;
    /* background: var(--primary); */
    border-radius: 3.125rem;
    color: #fff;
    display: block;
    font-size: 1.25rem;
    height: 3.125rem;
    line-height: 3.125rem;
    text-align: center;
    width: 3.125rem;
}
svg.vert-move {
  -webkit-animation: mover 2s infinite alternate;
  animation: mover 2s infinite alternate;
}
@-webkit-keyframes mover {
  0% { transform: translateX(0); }
  100% { transform: translateX(-10px); }
}
@keyframes mover {
  0% { transform: translateX(0); }
  100% { transform: translateX(-10px); }
}
.card-table a{text-decoration: none;}
/* .table tbody tr td {
  border-color: #e5e9ed;
  vertical-align: middle;
} */
.media a img {
  border-radius: .75rem;
  height: 5.4375rem;
  min-width: 5.4375rem;
}
.order_details_page .card {
  background-color: #fff;
  border: 0 solid transparent;
  border-radius: 1.75rem;
  box-shadow: 0 .25rem .25rem rgba(0, 0, 0, .1);
  height: calc(100% - 30px);
  margin-bottom: 1.875rem;
  position: relative;
  transition: all .5s ease-in-out;
}
.dataTablesCard {
  background-color: #fff;
  border-radius: 1.75rem !important;
}
.dataTablesCard > thead > tr > th{background: #fff !important;}
.dataTablesCard .bgl-danger {
  background: #fee6ea;
  border-color: #fee6ea;
}
.dataTablesCard .bgl-success {
  background: #e7fbe6;
  border-color: #e7fbe6;
}
.dataTablesCard .btn-rounded {
  border-radius: 2.5rem !important;    font-size: .813rem !important;padding: .625rem 1rem;
}
.customer-search .search-area .form-control {
  border: .0625rem solid #eee;
  border-bottom-left-radius: 3.125rem;
  border-right: 0;
  border-top-left-radius: 3.125rem;
}
.customer-search .search-area .input-group-text {
  background: #fff;
  border: .0625rem solid #eee;
  border-bottom-right-radius: 3.125rem;
  border-top-right-radius: 3.125rem;
}
.table.shadow-hover tbody tr {
  transition: all .5s;
}
.table.shadow-hover tbody tr:hover {
  background-color: #fff;
  box-shadow: 0 0 1.875rem rgba(253, 104, 62, .2);
  /* box-shadow: 0 0 1.875rem var(--rgba-primary-2); */
}
.shadow-hover tr:hover td {
  font-weight: 700;
}

.inventory_card.card{background-color: #fff;
  border: 0 solid transparent;
  border-radius: 1.75rem;
  box-shadow: 0 .25rem .25rem rgba(0, 0, 0, .1);
  height: calc(100% - 30px);
  margin-bottom: 1.875rem;
  position: relative;
  transition: all .5s ease-in-out;}
 .inventory_card .card-header {
  align-items: center;
  background: transparent;
  border-color: #e5e9ed;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 1.875rem 1.25rem;
  position: relative;
}
.inventory_card .card-title {
  color: #000;padding: 0;
  font-size: 1.25rem;
  font-weight: 500;
  text-transform: capitalize;
}
table.dataTable thead th {
  color: #000;
  font-size: 1.125rem;
  font-weight: 700;
  padding: 1.25rem .9375rem;
  text-transform: capitalize;
  white-space: nowrap;
}
table.dataTable tbody td {
  border-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  padding: 1.25rem .9375rem;
}
.sharp.btn-xs {
  height: 1.625rem;
  min-height: 1.625rem;
  min-width: 1.625rem;
  padding: .1875rem;
  width: 1.625rem;    border-radius: 1.125rem;
}
.inventory_card .btn-secondary {
  background-color: #624fd1;
  border-color: #624fd1;
  color: #fff;    border-radius: 1.125rem;
}
.inventory_card .btn.btn-danger, .inventory_card .btn.btn-info, .inventory_card .btn.btn-primary, .inventory_card .btn.btn-secondary, .inventory_card .btn.btn-success, .inventory_card .btn.btn-warning {
  color: #fff;font-size: 12px;
}
.shadow.btn-secondary {
  box-shadow: 0 .3125rem .9375rem 0 rgba(98, 79, 209, .2) !important;
}
.shadow.btn-secondary:hover{background-color: #624fd1;}
.fs-20, .fs-22 {
  line-height: 1.5;
}
.fs-20 {
  font-size: 1.25rem !important;
}
.form_modal .form-control {
  background: #fff;
  border: .0625rem solid #e5e9ed;
  border-radius: 1rem;
  color: #6e6e6e;
  height: 3.5rem;
  padding: .3125rem 1.25rem;height: 3rem;
}
.form_modal .form-control:focus {
  border-color: #fd683e;box-shadow:none;
}
@media only screen and (max-width: 1400px) {
  table.dataTable thead th {
      font-size: 1rem;
  }
  table.dataTable tbody td {
    font-size: .875rem;
    padding: .5rem .9375rem;
}
}
@media only screen and (max-width: 1199px) {
  .orders {
      padding: 0;
  }
  .orders li {
    padding: 0 .625rem;
}
.orders li a {
  height: 4.375rem;
  line-height: 3.75rem;
  width: 4.375rem;
}
.orders li a svg {
  width: 40px;
}
.orders li:after {
  top: 2.25rem;
}
.orders li:last-child:after{content: none;}
}
.card .text-black{font-size: 16px;font-weight: 500;}

@media only screen and (max-width: 991px) {
  .delivery-contact li a {
      height: 2.5rem;
      line-height: 2.5rem;
      width: 2.5rem;
  }
}
@media only screen and (max-width: 575px) {
  .orders {
    display: block;
}
.order_details_page  .card {
  border-radius: .375rem;
  height: calc(100% - .938rem);
  margin-bottom: .938rem;
}
.order_details_page .card-body {
  padding: 1rem;
}
  .orders li {
      padding: 0 0 1.5625rem;
      width: 100%;
  }
  .orders li a {
    border-width: .125rem;
    float: left;
    height: 3.125rem;
    line-height: 2.8rem;
    margin-right: .625rem;
    min-width: 3.125rem;
    width: 3.125rem;
}
.orders li a svg {
  height: 1.875rem;
  width: 1.875rem;
}
.orders li:after {
  height: 100%;
  left: 1.5625rem;
  width: .125rem;
}
.delivery-guy img {
  height: 3.125rem;
  width: 3.125rem;
}
.delivery-guy .h4, .delivery-guy h4 {
  font-size: .9375rem;
}
}
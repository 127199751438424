/**
* Template Name: NiceAdmin
* Template URL: https://bootstrapmade.com/nice-admin-bootstrap-admin-html-template/
* Updated: Apr 20 2024 with Bootstrap v5.3.3
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
:root {
  scroll-behavior: smooth;
  --primary-color: #2e9a3f;
  --primary-btn-color: #ff4136;
  --btn-label-color: #f6f9ff;
  --secondery-color:#9fa4a6;
  --rgba-primary-1: rgba(253, 62, 100, 0.15);
  --rgba-primary-2: rgba(62, 253, 97, 0.15);
  --white-color:#ffffff;
}

body {
  /* font-family: "Open Sans", sans-serif; */
  /* font-family: "MuseoModerno", sans-serif !important; */
  background: #fbfbfb !important;
  color: #444444;
}

a {
  color: #4154f1;
  text-decoration: none;
}

a:hover {
  color: #2f2f2f;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: "Nunito", sans-serif;  */
  font-family: "MuseoModerno", sans-serif !important;
  
  /* font-family: "Open Sans", sans-serif; */
}
.profile  h4,.profile-tab h4{  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important; }
p{ 
  /* font-family: "Open Sans", sans-serif; */
 }

/*--------------------------------------------------------------
# Main
--------------------------------------------------------------*/
#main {
  margin-top: 100px;
  padding: 50px 30px 20px;
  transition: all 0.3s;
  min-height: 700px;
}

@media (max-width: 1199px) {
  #main {
    padding: 10px;
  }
}

/*--------------------------------------------------------------
# Page Title
--------------------------------------------------------------*/
.pagetitle {
  margin-bottom: 10px;
}

.pagetitle h1 {
  font-size: 24px;
  margin-bottom: 0;
  font-weight: 600;
  color: #012970;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  background: #4154f1;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #6776f4;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Override some default Bootstrap stylings
--------------------------------------------------------------*/
/* Dropdown menus */
.dropdown-menu {
  border-radius: 4px;
  padding: 10px 0;
  animation-name: dropdown-animate;
  animation-duration: 0.2s;
  animation-fill-mode: both;
  border: 0;
  box-shadow: 0 5px 30px 0 rgba(82, 63, 105, 0.2);
}

.dropdown-menu .dropdown-header,
.dropdown-menu .dropdown-footer {
  text-align: center;
  font-size: 15px;
  padding: 10px 25px;
}

.dropdown-menu .dropdown-footer a {
  color: #444444;
  text-decoration: underline;
}

.dropdown-menu .dropdown-footer a:hover {
  text-decoration: none;
}

.dropdown-menu .dropdown-divider {
  color: #a5c5fe;
  margin: 0;
}

.dropdown-menu .dropdown-item {
  font-size: 14px;
  padding: 10px 15px;
  transition: 0.3s;
}

.dropdown-menu .dropdown-item i {
  margin-right: 10px;
  font-size: 18px;
  line-height: 0;
}

.dropdown-menu .dropdown-item:hover {
  background-color: #f6f9ff;
}

@media (min-width: 768px) {
  .dropdown-menu-arrow::before {
    content: "";
    width: 13px;
    height: 13px;
    background: #fff;
    position: absolute;
    top: -7px;
    right: 20px;
    transform: rotate(45deg);
    border-top: 1px solid #eaedf1;
    border-left: 1px solid #eaedf1;
  }
}

@keyframes dropdown-animate {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

  0% {
    opacity: 0;
  }
}

/* Light Backgrounds */
.bg-primary-light {
  background-color: #cfe2ff;
  border-color: #cfe2ff;
}

.bg-secondary-light {
  background-color: #e2e3e5;
  border-color: #e2e3e5;
}

.bg-success-light {
  background-color: #d1e7dd;
  border-color: #d1e7dd;
}

.bg-danger-light {
  background-color: #f8d7da;
  border-color: #f8d7da;
}

.bg-warning-light {
  background-color: #fff3cd;
  border-color: #fff3cd;
}

.bg-info-light {
  background-color: #cff4fc;
  border-color: #cff4fc;
}

.bg-dark-light {
  background-color: #d3d3d4;
  border-color: #d3d3d4;
}

.widget-stat.card{background-color: #fff;
  border: 0 solid transparent;
  border-radius: 1.75rem;
  box-shadow: 0 .25rem .25rem rgba(0, 0, 0, .1);
  /* height: calc(100% - 30px); */
  margin-bottom: 1.875rem;
  position: relative;
  transition: all .5s ease-in-out;}
.widget-stat .media{    align-items: center;
  padding: 0;display: flex;justify-content: space-between;}
  .widget-stat .media>span {
    align-items: center;
    border-radius: 3.125rem;
    color: #464a53;
    display: flex;
    font-size: 32px;
    height: 5.3125rem;
    justify-content: center;
    min-width: 5.3125rem;
    padding: .625rem .75rem;
    width: 5.3125rem;
}
/* .media .media-body{text-align: right;} */
.bgl-primary{    background: rgba(253, 104, 62, .1);}
.text-primary {  color: #fd683e !important;}
.text-warning{color: #ffa41d !important;}
.bgl-warning {
  background: #ffdba7;
  border-color: #ffdba7;
} 
.bgl-danger {
  background: #fee6ea;
  border-color: #fee6ea;
}
.text-danger {
  color: #f72b50 !important;
}
.bgl-success {
  background: #e7fbe6;
  border-color: #e7fbe6;
}
.text-success {
  color: #68e365 !important;
}

.card.bg-primary1 {
  background-color: var(--primary-color);color: #fff;
  border-color: var(--primary-color);
}

.card.bg-secondary {
  background-color: #fd683e;color: #fff;
  border-color: #fd683e;
}

.card.bg-success {
  background-color: #624fd1;color: #fff;
  border-color: #624fd1;
}

.card.bg-danger {
  background-color: #f8d7da;color: #fff;
  border-color: #f8d7da;
}

.card.bg-warning {
  background-color: #ffa41d;color: #fff;
  border-color: #ffa41d;
}

.card.bg-info {
  background-color: #b48dd3;color: #fff;
  border-color: #b48dd3;
}
.widget-stat[class*=bg-] .media>span {
  background-color: hsla(0, 0%, 100%, .25);
  color: #fff;
}
.media svg{color: #fff;}
.widget-stat .media .media-body .h4, .widget-stat .media .media-body h4 {
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 1.2;
  margin: 0;
}
/* Card */
.card {
  margin-bottom: 30px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}

.card-header,
.card-footer {
  border-color: #ebeef4;
  background-color: #fff;
  color: #798eb3;
  padding: 15px;
}

.card-title {
  /* padding: 20px 0 15px 0; */
  font-size: 18px;
  font-weight: 500;
  color: #012970;
  font-family: "Poppins", sans-serif;
}

.card-title span {
  color: #899bbd;
  font-size: 14px;
  font-weight: 400;
}

.card-body {
  padding: 0 20px 20px 20px;
}

.card-img-overlay {
  background-color: rgba(255, 255, 255, 0.6);
}
.coin-tabs {
  /* background: rgba(253, 104, 62, .1); */
  /* background: var(--rgba-primary-1); */
  border-radius: 2.1875rem;
  padding: .425rem 1.875rem;margin: auto;width: 100%;
}
.coin-tabs .nav-tabs {
  border: 0;justify-content: space-evenly;align-items: center;
}
.coin-tabs .nav-link {
  display: block;
  padding: .5rem 1rem;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}
.coin-tabs .nav-tabs .nav-link {
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem;
  margin-bottom: -1px;
}
/* .coin-tabs .nav-tabs .nav-item{flex: 1;} */
.coin-tabs .nav-tabs .nav-item .nav-link {
  background: transparent;
  border: 0;
  border-radius: 2.1875rem;
  color: #000;
  font-weight: 600;
  margin: 3px 0;font-size: 12px;
}
.coin-tabs .nav-tabs .nav-item .nav-link.active {
  background: #ec1d25;
  background: var(--primary-btn-color);
  color: #fff;
}
.brand-list .btn-check:checked + .btn, :not(.btn-check) + .btn:active, .brand-list .btn:first-child:active, .brand-list .btn.active, .brand-list .btn.show{
  background: #ec1d25;
    background: var(--primary-btn-color);
    color: #fff;    border-radius: 2.1875rem;border: 0;
}
.brand-list .btn-check:checked + .selected_bg1{background: #ff4136 ;}
.brand-list .btn-check:checked + .selected_bg2{background: #624fd1;}
.brand-list .btn-check:checked + .selected_bg5{background: #b48dd3;}
.brand-list .btn-check:checked + .selected_bg4{background: #2e9a3f;}
.brand-list .btn-check:checked + .selected_bg6{background: #ffa41d;}
.brand-list .btn-check:checked + .selected_bg3{background: #fd683e;}
.brand-list .btn-outline-primary{border: 0;color: #000;font-size: 15px;font-weight: 500;padding: 10px;background: #ffe2e8;
  border-radius: 2.1875rem;
  padding: 10px 15px;margin: 5px 0;}
/* .brand-list .btn-check + .btn-outline-primary:hover{background-color: #ffe2e8;color: #000;} */
.container-fluid .card-header {
  align-items: center;
  background: transparent;
  border-color: #e5e9ed;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 1.875rem 1.25rem;
  position: relative;
}
.container-fluid .btn-primary{border: 0;line-height: 30px;}
.container-fluid hr{border-color:#e5e9ed;opacity:1}
.react-bootstrap-table table{table-layout: auto;}
.table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}
.row.react-bootstrap-table-pagination {margin: 0;}
.row .pagination.react-bootstrap-table-page-btns-ul{justify-content: end;}
/* .table thead th:nth-child(even){background-color:var(--primary-color); color: #fff;border: 0;} */
/* .table thead th:nth-child(odd){background-color:var(--primary-btn-color); color: #fff;border: 0;} */
.table thead th{background-color:var(--primary-color); color: #fff;border: 0;vertical-align: middle;}
button:focus:not(:focus-visible){border: none;}
.table tbody tr:nth-of-type(odd) {
  background-color: #f2f4fa;
}
.table tbody tr:hover {
  background-color: #ffbf5f;
}
.table .dropstart .dropdown-toggle::before{border: none;}

.table tr td{background: none;cursor: pointer;}
.shadow.btn-primary {
  box-shadow: 0 .3125rem .9375rem 0 rgba(253, 104, 62, .2) !important;
  box-shadow: 0 .3125rem .9375rem 0 var(--rgba-primary-2) !important;
}
.container-fluid .form-select:focus{box-shadow: none;border-color: var(--primary-btn-color);}
/* Alerts */
.alert-heading {
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
}

/* Close Button */
.btn-close {
  background-size: 25%;
}

.btn-close:focus {
  outline: 0;
  box-shadow: none;
}

/* Accordion */
.accordion-item {
  border: 1px solid #ebeef4;
}

.accordion-button:focus {
  outline: 0;
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: #012970;
  background-color: #f6f9ff;
}

.accordion-flush .accordion-button {
  padding: 15px 0;
  background: none;
  border: 0;
}

.accordion-flush .accordion-button:not(.collapsed) {
  box-shadow: none;
  color: #4154f1;
}

.accordion-flush .accordion-body {
  padding: 0 0 15px 0;
  color: #3e4f6f;
  font-size: 15px;
}

/* Breadcrumbs */
.breadcrumb {
  font-size: 14px;
  font-family: "Nunito", sans-serif;
  color: #899bbd;
  font-weight: 600;margin: 0 !important; 
}

.breadcrumb a {
  color: #899bbd;
  transition: 0.3s;
}

.breadcrumb a:hover {
  color: #51678f;
}

.breadcrumb .breadcrumb-item::before {
  color: #899bbd;
}

.breadcrumb .active {
  color: #51678f;
  font-weight: 600;
}

/* Bordered Tabs */
.nav-tabs-bordered {
  border-bottom: 2px solid #ebeef4;
}

.nav-tabs-bordered .nav-link {
  margin-bottom: -2px;
  border: none;
  /* color: #2c384e; */
  color: var(--primary-btn-color)
}

.nav-tabs-bordered .nav-link:hover,
.nav-tabs-bordered .nav-link:focus {
  color: #4154f1;
}

.nav-tabs-bordered .nav-link.active {
  background-color: #fff;
  color: #4154f1;
  border-bottom: 2px solid #4154f1;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.logo {
  line-height: 1;text-decoration: none;
}
/* .logo span{text-decoration: none;color: #ce1212;} */

@media (min-width: 1200px) {
  .logo {
    width: 280px;
  }
}

/* .logo img {
  max-height: 26px;
  margin-right: 6px;
} */

.logo img {
  max-height: 80px;
  margin-right: 8px;
  width: auto;
}

.logo span {
  font-size: 26px;
  font-weight: 700;
  color: #012970;text-decoration: none;color: var(--primary-color);
  font-family: "Nunito", sans-serif;
}

.header {
  transition: all 0.5s;
  z-index: 997;
  height: 5.5rem;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  background-color: #fff;
  padding-left: 20px;height: 100px;
  box-shadow: none;
  border-bottom: .0625rem solid #eee;padding-left: 0;
  /* z-index: 1; */
  /* Toggle Sidebar Button */
  /* Search Bar */
}

.header .toggle-sidebar-btn {
  font-size: 32px;
  padding-left: 10px;
  cursor: pointer;
  /* color: #012970; */
  color: var(--primary-btn-color);
}

.header .search-bar {
  min-width: 360px;
  padding: 0 20px;
}

@media (max-width: 1199px) {
  .header .search-bar {
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    padding: 20px;
    box-shadow: 0px 0px 15px 0px rgba(1, 41, 112, 0.1);
    background: white;
    z-index: 9999;
    transition: 0.3s;
    visibility: hidden;
    opacity: 0;
  }

  .header .search-bar-show {
    top: 60px;
    visibility: visible;
    opacity: 1;
  }
}

.header .search-form {
  width: 100%;
}

/* .header .search-form input {
  border: 0;
  font-size: 14px;
  color: #012970;
  border: 1px solid rgba(1, 41, 112, 0.2);
  padding: 7px 38px 7px 8px;
  border-radius: 3px;
  transition: 0.3s;
  width: 100%;
} */

.header .search-form input:focus,
.header .search-form input:hover {
  outline: none;
  box-shadow: 0 0 10px 0 rgba(1, 41, 112, 0.15);
  border: 1px solid rgba(1, 41, 112, 0.3);
}

 .search-form button {
  border: 0;
  padding: 0;
  margin-left: -30px;
  background: none;
}

.header .search-form button i {
  color: #012970;
}
.mr-2{margin-right: 10px;}
.ml-2{margin-left: 10px;}
/*--------------------------------------------------------------
# Header Nav
--------------------------------------------------------------*/
.header-nav ul {
  list-style: none;
}

.header-nav>ul {
  margin: 0;
  padding: 0;
}

.header-nav .nav-icon {
  font-size: 22px;
  color: #969ba0;
  margin-right: 25px;
  position: relative;
}

.header-nav .nav-profile {
  color: #969ba0;
}

.header-nav .nav-profile img {
  max-height: 36px;
}

.header-nav .nav-profile span {
  font-size: 14px;
  font-weight: 600;
}

.header-nav .badge-number {
  position: absolute;
  inset: -2px -5px auto auto;
  font-weight: normal;
  font-size: 12px;
  padding: 3px 6px;
}

.header-nav .notifications {
  inset: 8px -15px auto auto !important;
}

.header-nav .notifications .notification-item {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  transition: 0.3s;
}

.header-nav .notifications .notification-item i {
  margin: 0 20px 0 10px;
  font-size: 24px;
}

.header-nav .notifications .notification-item h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.header-nav .notifications .notification-item p {
  font-size: 13px;
  margin-bottom: 3px;
  color: #919191;
}

.header-nav .notifications .notification-item:hover {
  background-color: #f6f9ff;
}

.header-nav .messages {
  inset: 8px -15px auto auto !important;
}

.header-nav .messages .message-item {
  padding: 15px 10px;
  transition: 0.3s;
}

.header-nav .messages .message-item a {
  display: flex;
}

.header-nav .messages .message-item img {
  margin: 0 20px 0 10px;
  max-height: 40px;
}

.header-nav .messages .message-item h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #444444;
}

.header-nav .messages .message-item p {
  font-size: 13px;
  margin-bottom: 3px;
  color: #919191;
}

.header-nav .messages .message-item:hover {
  background-color: #f6f9ff;
}

.header-nav .profile {
  min-width: 240px;
  padding-bottom: 0;
  top: 8px !important;
}

.header-nav .profile .dropdown-header h6 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 600;
  color: #444444;
}

.header-nav .profile .dropdown-header span {
  font-size: 14px;
}

.header-nav .profile .dropdown-item {
  font-size: 14px;
  padding: 10px 15px;
  transition: 0.3s;
}

.header-nav .profile .dropdown-item i {
  margin-right: 10px;
  font-size: 18px;
  line-height: 0;
}

.header-nav .profile .dropdown-item:hover {
  background-color: #f6f9ff;
}

/*--------------------------------------------------------------
# Sidebar
--------------------------------------------------------------*/
.sidebar {
  position: fixed;
  top: 5.5em;
  left: 0;
  bottom: 0;
  width: 18rem;
  z-index: 996;
  transition: all 0.3s;
  padding: 20px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #aab7cf transparent;
  /* box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1); */
  background-color: #fff;
  box-shadow: 0 1.25rem 1.875rem 0 rgba(0, 0, 0, .02);    z-index: 1030;scrollbar-width: none;
}
.header_inn{height: 100%; box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);width: 18rem;}
.toggle-sidebar .header_inn{box-shadow: none;}

.hamburger.hide-sm{display: none;}
.hamburger.hide-md{display: block;}
@media (max-width: 1199px) {
  .sidebar {
    left: -300px;
  }
  .header_inn{box-shadow: none;}
  .hamburger.hide-sm{display: block;}
  .hamburger.hide-md{display: none;}
}

.sidebar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #fff;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #aab7cf;
}

@media (min-width: 1200px) {

  #main,
  #footer {
    margin-left: 300px;
  }
}

@media (max-width: 1199px) {
  .toggle-sidebar .sidebar {
    left: 0;
  }
}

@media (min-width: 1200px) {

  .toggle-sidebar #main,
  .toggle-sidebar #footer {
    margin-left: 0;
  }

  .toggle-sidebar .sidebar {
    left: -300px;
  }
  /* .header_inn{box-shadow: none;} */
}

.sidebar-nav {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav li {
  padding: 0;
  margin: 0;
  list-style: none;font-weight: normal;
}

.sidebar-nav .nav-item {
  margin-bottom: 5px;
}

.sidebar-nav .nav-heading {
  font-size: 11px;
  text-transform: uppercase;
  color: #899bbd;
  font-weight: 600;
  margin: 10px 0 5px 15px;
}

.sidebar-nav .nav-link {
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  font-weight: 500;
  /* color: #4154f1; */
  color: var( --primary-btn-color);
  transition: 0.3;
  /* background: #f6f9ff; */
  padding: 10px 15px;
  border-radius: 4px;
  border-radius: 2rem;
    font-family: MuseoModerno, cursive;
    /* font-family: var(--font-family-title); */
    font-size: 1rem;
    padding: .5rem 2.1875rem .5rem .625rem;
    transition: all .5s ease-in-out;
    background: var(--rgba-primary-1);

}

.sidebar-nav .nav-link i {
  font-size: 18px;
  margin-right: 10px;
  /* color: #4154f1; */
  color: var(--primary-color);
  background: var(--primary-btn-color);
  /* box-shadow: 0 .5625rem .5625rem rgba(253, 104, 62, .3); */
  box-shadow: 0 .5625rem .5625rem var(--rgba-primary-1);
  color: #fff;
  font-weight: 300;
  margin-right: 10px;
  border-radius: 3.1875rem;
  height: 50px;
  line-height: 50px;
  text-align: center;transition: all .5s ease-in-out;
  width:50px;margin-right: 10px;
}

.sidebar-nav .nav-link.collapsed {
  color: var( --secondery-color);
  background: #fff;transition: all .5s ease-in-out;
  
}
.sidebar-nav .nav-link.collapsed.inn{  
  color: var( --secondery-color);
  background: #fff;transition: all .5s ease-in-out;
}

.sidebar-nav .nav-link.collapsed i {
  color: #969ba0;
  border-radius: 0;
  height: 50px;
  line-height: 50px;
  text-align: center;
  width: 50px;
  background: none;margin-right: 0px;transition: all .5s ease-in-out;box-shadow: none;
}
.sidebar-nav .nav-link.collapsed.inn i {

  color: #969ba0;
  border-radius: 0;
  height: 50px;
  line-height: 50px;
  text-align: center;
  width: 50px;
  background: none;margin-right: 0px;transition: all .5s ease-in-out;box-shadow: none;
}
.sidebar-nav .nav-link:hover i.bi-caret-right-fill,.sidebar-nav .nav-link i.bi-caret-right-fill{background: none;color: var(--primary-btn-color) ;margin: 0;    right: -30px;
  position: relative;box-shadow: none;font-size: 14px;
}
.sidebar-nav .nav-link.active i.bi-caret-right-fill{background: none;color: var(--primary-btn-color) ;margin: 0;    right: -30px;
  position: relative;box-shadow: none;font-size: 14px;
}
.header-nav .nav-profile img:after{background: linear-gradient(90deg, #fd683e, rgba(253, 163, 62, .75));
  border-radius: inherit;
  bottom: 0;
  content: "";
  left: 0;
  margin: -.1875rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;}
  .btn-primary{border-radius: 15px !important;background-color: var(--primary-btn-color) !important;}

  .btn.btn-secondry{background: #2e9a3f !important;}
  .btn.btn-secondry:hover{background: #186c24;}

.sidebar-nav .nav-link:hover,.sidebar-nav .nav-link.active {
  color: #000;
  background: var(--rgba-primary-1);
  color: var(--primary-btn-color);
}

.sidebar-nav .nav-link:hover i,.sidebar-nav .nav-link.active i {
  color: #ffffff;
  background: var(--primary-btn-color);
  /* box-shadow: 0 .5625rem .5625rem rgba(253, 104, 62, .3); */
  box-shadow: 0 .5625rem .5625rem var(--rgba-primary-1);
  font-weight: 300;
  margin-right: 10px;
  border-radius: 3.1875rem;
  height: 3.1875rem;
  line-height: 3.1875rem;
  text-align: center;
  width: 3.1875rem;
}
.sidebar-nav .nav-link:hover i.bi-caret-right-fill,.sidebar-nav .nav-link:hover i.bi-caret-left-fill {
  color: var(--primary-btn-color) ;font-size: 14px;
}
.sidebar-nav .nav-link.active i.bi-caret-right-fill,.sidebar-nav .nav-link.active i.bi-caret-left-fill {
  color: var(--primary-btn-color) ;font-size: 14px;
}
.sidebar-nav .nav-link.collapsed i.bi-caret-right-fill, .sidebar-nav .nav-link.collapsed i.bi-caret-left-fill{color: #c8c8c8;font-size: 14px;}
.sidebar-nav .nav-link.collapsed:hover i.bi-caret-right-fill, .sidebar-nav .nav-link.collapsed:hover i.bi-caret-left-fill{color: var(--primary-btn-color);font-size: 14px;}
/* .sidebar-nav .nav-link.collapsed.active i.bi-caret-right-fill, .sidebar-nav .nav-link.collapsed.active i.bi-caret-left-fill{color: var(--primary-btn-color);font-size: 14px;} */


.sidebar-nav .nav-link .bi-chevron-down {
  margin-right: 0;
  transition: transform 0.2s ease-in-out;
}

.sidebar-nav .nav-link:not(.collapsed) .bi-chevron-down {
  transform: rotate(180deg);
}

.sidebar-nav .nav-content {
  padding: 5px 0 0 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-content a {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #012970;
  transition: 0.3;
  padding: 10px 0 10px 40px;
  transition: 0.3s;
  color: #9fa4a6 !important;
  display: block;
  outline-width: 0;
  padding: .625rem 1.875rem;
  position: relative;
  text-decoration: none;    font-size: .9075rem;
  padding-bottom: .5rem;font-weight: normal;
}


.sidebar-nav .nav-content a i {
  font-size: 8px;
  margin-right: 20px;
  line-height: 0;
  border-radius: 50%;
}

.sidebar-nav .nav-content a:hover,
.sidebar-nav .nav-content a.active {
  color: var(--primary-btn-color) !important;
}

.sidebar-nav .nav-content a.active i {
  color: var(--primary-btn-color) !important;
}
.hamburger {
  display: inline-block;
  position: relative;
  top: 0;
  transition: all .3s ease-in-out 0s;
  width: 1.625rem;
  z-index: 999;
}
.hamburger .line {
  background: #000;
  border-radius: .1875rem;
  display: block;
  height: .1875rem;
  margin-bottom: .375rem;
  margin-right: auto;
  margin-top: .375rem;
  transition: all .3s ease-in-out;
}
.hamburger .line:first-child, .hamburger .line:nth-child(2) {
  width: 1.625rem;
}
.hamburger .line:nth-child(3) {
  width: .9375rem;
}
.hamburger:hover .line {
  width: 1.625rem;
}
.toggle-sidebar .hamburger .line {
  /* background-color: #fd683e; */
  background-color: var(--primary-btn-color);
}

/*--------------------------------------------------------------
# Dashboard
--------------------------------------------------------------*/
/* Filter dropdown */
.dashboard .filter {
  position: absolute;
  right: 0px;
  top: 15px;
}

.dashboard .filter .icon {
  color: #aab7cf;
  padding-right: 20px;
  padding-bottom: 5px;
  transition: 0.3s;
  font-size: 16px;
}

.dashboard .filter .icon:hover,
.dashboard .filter .icon:focus {
  color: #4154f1;
}

.dashboard .filter .dropdown-header {
  padding: 8px 15px;
}

.dashboard .filter .dropdown-header h6 {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #aab7cf;
  margin-bottom: 0;
  padding: 0;
}

.dashboard .filter .dropdown-item {
  padding: 8px 15px;
}

/* Info Cards */
.dashboard .info-card {
  padding-bottom: 10px;
}

.dashboard .info-card h6 {
  font-size: 28px;
  color: #012970;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.dashboard .card-icon {
  font-size: 32px;
  line-height: 0;
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  flex-grow: 0;
}

.dashboard .sales-card .card-icon {
  color: #4154f1;
  background: #f6f6fe;
}

.dashboard .revenue-card .card-icon {
  color: #2eca6a;
  background: #e0f8e9;
}

.dashboard .customers-card .card-icon {
  color: #ff771d;
  background: #ffecdf;
}

/* Activity */
.dashboard .activity {
  font-size: 14px;
}

.dashboard .activity .activity-item .activite-label {
  color: #888;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  min-width: 64px;
}

.dashboard .activity .activity-item .activite-label::before {
  content: "";
  position: absolute;
  right: -11px;
  width: 4px;
  top: 0;
  bottom: 0;
  background-color: #eceefe;
}

.dashboard .activity .activity-item .activity-badge {
  margin-top: 3px;
  z-index: 1;
  font-size: 11px;
  line-height: 0;
  border-radius: 50%;
  flex-shrink: 0;
  border: 3px solid #fff;
  flex-grow: 0;
}

.dashboard .activity .activity-item .activity-content {
  padding-left: 10px;
  padding-bottom: 20px;
}

.dashboard .activity .activity-item:first-child .activite-label::before {
  top: 5px;
}

.dashboard .activity .activity-item:last-child .activity-content {
  padding-bottom: 0;
}

/* News & Updates */
.dashboard .news .post-item+.post-item {
  margin-top: 15px;
}

.dashboard .news img {
  width: 80px;
  float: left;
  border-radius: 5px;
}

.dashboard .news h4 {
  font-size: 15px;
  margin-left: 95px;
  font-weight: bold;
  margin-bottom: 5px;
}

.dashboard .news h4 a {
  color: #012970;
  transition: 0.3s;
}

.dashboard .news h4 a:hover {
  color: #4154f1;
}

.dashboard .news p {
  font-size: 14px;
  color: #777777;
  margin-left: 95px;
}

/* Recent Sales */
.dashboard .recent-sales {
  font-size: 14px;
}

.dashboard .recent-sales .table thead {
  background: #f6f6fe;
}

.dashboard .recent-sales .table thead th {
  border: 0;
}

.dashboard .recent-sales .dataTable-top {
  padding: 0 0 10px 0;
}

.dashboard .recent-sales .dataTable-bottom {
  padding: 10px 0 0 0;
}

/* Top Selling */
.dashboard .top-selling {
  font-size: 14px;
}

.dashboard .top-selling .table thead {
  background: #f6f6fe;
}

.dashboard .top-selling .table thead th {
  border: 0;
}

.dashboard .top-selling .table tbody td {
  vertical-align: middle;
}

.dashboard .top-selling img {
  border-radius: 5px;
  max-width: 60px;
}

/*--------------------------------------------------------------
# Icons list page
--------------------------------------------------------------*/
.iconslist {
  display: grid;
  max-width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 1.25rem;
  padding-top: 15px;
}

.iconslist .icon {
  background-color: #fff;
  border-radius: 0.25rem;
  text-align: center;
  color: #012970;
  padding: 15px 0;
}

.iconslist i {
  margin: 0.25rem;
  font-size: 2.5rem;
}

.iconslist .label {
  font-family: var(--bs-font-monospace);
  display: inline-block;
  width: 100%;
  overflow: hidden;
  padding: 0.25rem;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #666;
}

/*--------------------------------------------------------------
# Profie Page
--------------------------------------------------------------*/
.profile .profile-card img {
  max-width: 120px;
}

.profile .profile-card h2 {
  font-size: 24px;
  font-weight: 700;
  color: #2c384e;
  margin: 10px 0 0 0;
}

.profile .profile-card h3 {
  font-size: 18px;
}

.profile .profile-card .social-links a {
  font-size: 20px;
  display: inline-block;
  color: rgba(1, 41, 112, 0.5);
  line-height: 0;
  margin-right: 10px;
  transition: 0.3s;
}

.profile .profile-card .social-links a:hover {
  color: #012970;
}

.profile .profile-overview .row {
  margin-bottom: 20px;
  font-size: 15px;
}

.profile .profile-overview .card-title {
  color: #012970;
}

.profile .profile-overview .label {
  font-weight: 600;
  color: rgba(1, 41, 112, 0.6);
}

.profile .profile-edit label {
  font-weight: 600;
  color: rgba(1, 41, 112, 0.6);
}

.profile .profile-edit img {
  max-width: 120px;
}

/*--------------------------------------------------------------
# F.A.Q Page
--------------------------------------------------------------*/
.faq .basic h6 {
  font-size: 18px;
  font-weight: 600;
  color: #4154f1;
}

.faq .basic p {
  color: #6980aa;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-box {
  padding: 28px 30px;
}

.contact .info-box i {
  font-size: 38px;
  line-height: 0;
  color: #4154f1;
}

.contact .info-box h3 {
  font-size: 20px;
  color: #012970;
  font-weight: 700;
  margin: 20px 0 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 0;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #4154f1;
}

.contact .php-email-form input {
  padding: 10px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type=submit] {
  background: #4154f1;
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type=submit]:hover {
  background: #5969f3;
}
.authincation-content {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 35px 0 hsla(215, 9%, 64%, .15);
}
.login-form {
  min-width: 100%;
  position: fixed;
  text-align: center;
  top: 0;
  left: 0;
  min-height: 100%;
  /* font-size: 2.5rem */background: #fbfbfb;
}
.login-form .box-skew .authincation-content {
  box-shadow: none;
  padding: 50px;
  position: absolute;
  right: 50px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 500px;
  z-index: 1;
}
.login-form .box-skew, .login-form .box-skew1 {
  height: 100vh;
  min-height: 100vh;
  position: relative;
}
.login-form .box-skew1:after {
  /* background-image: url(/react/demo/static/media/bg-login.5883497….jpg); */
  background: #faf7f2;
  background-size: cover;
  content: "";
  height: 100%;
  left: -30px;
  position: absolute;
  top: 0;
  -webkit-transform: skew(-5deg);
  transform: skew(-5deg);
  width: 120%;
  z-index: 1;
}
.btn-primary.loginbtn{background:var(--primary-btn-color)}
.error{color: #ec1d25;font-size: 13px;}
.login-form .box-skew .authincation-content:after {
  background-color: #fff;
  box-shadow: 0 12px 23px 0 rgba(62, 73, 84, .08);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 200%;
  z-index: -1;
}
.inner-content{z-index: 2;}
/* .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown), .form-floating > .form-control-plaintext:focus, .form-floating > .form-control-plaintext:not(:placeholder-shown){
  padding-top: 0.625rem !important;
} */
 .form_modal  .form-floating2 .form-control{height: 58px;}
.login_btn_wrap .btn_signin {
  font-size: 16px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  background-color: #57b846;
  box-shadow: 0 10px 30px 0px rgba(87, 184, 70, 0.5);
  -moz-box-shadow: 0 10px 30px 0px rgba(87, 184, 70, 0.5);
  -webkit-box-shadow: 0 10px 30px 0px rgba(87, 184, 70, 0.5);
  -o-box-shadow: 0 10px 30px 0px rgba(87, 184, 70, 0.5);
  -ms-box-shadow: 0 10px 30px 0px rgba(87, 184, 70, 0.5);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}
.login_btn_wrap .btn_signin:hover {
  color: #fff;
  background-color: #cf2227;
  box-shadow: 0 10px 30px 0px rgba(184, 76, 70, 0.5);
  -moz-box-shadow: 0 10px 30px 0px rgba(184, 70, 70, 0.5);
  -webkit-box-shadow: 0 10px 30px 0px rgba(184, 70, 70, 0.5);
  -o-box-shadow: 0 10px 30px 0px rgba(184, 70, 70, 0.5);
  -ms-box-shadow: 0 10px 30px 0px rgba(184, 70, 70, 0.5);
}
.container-fluid .card{    background-color: #fff;
  border: 0 solid transparent;
  border-radius: 1.75rem;
  box-shadow: 0 .25rem .25rem rgba(0, 0, 0, .1);
  /* height: calc(100% - 30px); */
  margin-bottom: 1.875rem;
  position: relative;
  transition: all .5s ease-in-out;}
  .page-titles {
    background: #fff;
    border-radius: 1rem;
    margin: 0 0 1.875rem;
    padding: .9375rem 2.5rem;
}
.profile .profile-photo {
  max-width: 6.25rem;
  position: relative;
}
.profile-info .text-primary.h4, .profile-info h4.text-primary {
  color: #ec1d25 !important;
  color: var(--primary-btn-color) !important;
}
.text-primary {
  color: #ec1d25 !important;
  color: var(--primary-btn-color) !important;
}
.container-fluid h4 {
  font-size: 16px;
}
.profile-info p {
  color: #828690;font-size: 12px;text-align: left;margin: 0;
}
.profile-name,.profile-email{display: flex;
  flex-direction: column;
  justify-content: center;}
.profile-details{width: 100%;}
.light.btn-primary {
  background-color: rgba(253, 104, 62, .1) !important;
  background-color: var(--rgba-primary-1) !important;
  border-color: rgba(253, 104, 62, .1) !important;
  border-color: var(--rgba-primary-1) !important;
  color: #ec1d25;border-radius: 15px;
  color: var(--primary-btn-color);
}
.light.btn-primary:hover {
  background-color: #ec1d25;
  background-color: var(--primary-btn-color);
  border-color: #ec1d25;
  border-color: var(--primary-btn-color);
  color: #fff;
}
.nav-tabs .nav-link {
  display: block;
  padding: .5rem 1rem;color: #828690;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}
.profile-tab .nav-item .nav-link.active, .profile-tab .nav-item .nav-link:hover {
  background: transparent;
  border: 0;
  border-bottom: .0125rem solid #ec1d25;
  border-bottom: .0125rem solid var(--primary-btn-color);
  color: #ec1d25;
  color: var(--primary-btn-color);
}
.custom-tab-1 .fade:not(.show){opacity: 1;}
.tab-pane p{font-size: 12px;}
.form-label {
  font-size: 13px;
}
.settings-form .form-control{font-size: 14px;height: 42px;}
.profile-tab h5{font-size: 15px;}
.email-list .message .col-mail-2 {
  bottom: 0;
  left: 5.625rem;
  position: absolute;
  right: 0;
  top: 0;
}
.email-list .message .col-mail-2 .subject {
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 5.5rem;
  text-overflow: ellipsis;
  top: 0;
  white-space: nowrap;
}
.email-list .message a {
  color: #828690;font-size: 12px;
}
.email-list .message .col-mail-2 .date {
  position: absolute;
  right: 0;
  top: 0;
}
.email-list .message {
  cursor: default;
  display: block;
  height: 3.125rem;
  line-height: 3.125rem;
  position: relative;
  transition-duration: .3s;
}
.email-list .message-single i {
  color: #89879f;
  font-size: 1.125rem;
  padding-left: .4rem;
}
.form-check {
  display: block;
  margin-bottom: .125rem;
  min-height: 1.3125rem;
  padding-left: 1.5em;line-height: normal;
}
.form-check-input:checked {
  background-color: #ec1d25;
  background-color: var(--primary-btn-color) !important;
  border-color: #ec1d25;
  border-color: var(--primary-btn-color) !important;
}
.form-check-input:focus{box-shadow: none !important;}
.profile-tab{min-height: 600px;}
.form-control:focus{box-shadow: none !important;    border-color: #ec1d25 !important;}
.pagination {
  display: flex;
  list-style: none;
  padding-left: 0;
}
.pagination-gutter .page-item {
  margin-right: .4375rem;
}
.pagination .page-item .page-link {
  color: #6e6e6e;
  transition: all .5s;border-radius: 100%;width: 38px;height: 38px;margin: 0 10px;
}
.pagination-gutter .page-item .page-link {
  border-radius: 1.75rem !important;
}
.pagination-primary .page-item .page-link {
  background: rgba(253, 104, 62, .1);
  background: var(--rgba-primary-1);
  border-color: rgba(253, 104, 62, .1);
  border-color: var(--rgba-primary-1);
  color: #fd3e6b;
  color: var(--primary-btn-color);
}
.pagination-primary .page-item.active .page-link, .pagination-primary .page-item:hover .page-link {
  background: #ec1d25;
  background: var(--primary);
  border-color: #ec1d25;
  border-color: var(--primary);
  box-shadow: 0 .625rem 1.25rem 0 rgba(253, 104, 62, .2);
  box-shadow: 0 .625rem 1.25rem 0 var(--rgba-primary-2);
}
.pagination .page-item.active .page-link {
  background: #ec1d25;
  background: var(--primary-btn-color);border-radius: 100%;width: 38px;height: 38px;
}
.pagination .page-item.active .page-link {
  background-color: #ec1d25;
  background-color: var(--primary-btn-color);
  border-color: #ec1d25;
  border-color: var(--primary-btn-color);
  box-shadow: 0 .625rem 1.25rem 0 rgba(253, 104, 62, .2);
  box-shadow: 0 .625rem 1.25rem 0 var(--rgba-primary-1);
  color: #fff;
}
.pagination.pagination-sm .page-item .page-link {
  font-size: .875rem;
  height: 1.875rem;
  line-height: 1.875rem;
  padding: 0;
  width: 1.875rem;text-align: center;
}
.pagination.no-bg li:not(.page-indicator):not(.active) .page-link {
  background: transparent;
  border-color: transparent;
}
.profile-edit{display: flex;
  justify-content: end;
  align-items: center;}
.form-control{border-radius: 20px !important;height: 48px;}
.container-fluid .card-title{font-size: 24px;    font-weight: 600;}
.cart-item {
  position: relative;
  margin-bottom: 30px;
  padding: 0 50px 0 10px;
  background-color: #f9f9f9;
  box-shadow: 0 12px 20px 1px rgba(64, 64, 64, .09);
  border: 1px solid #e1e1e1;
}
.cart-item .cart-item-product-title {
  transition: color, 0.3s;
  color: #343b43;
  font-size: 16px;
  font-weight: bold;
}
.cart-item .cart-item-product-info > span.add_extra {
  color: #ec1d25;font-size: 13px;
}
.cart-item-product{text-decoration: none;text-align: left;color: #000;}
.added_count {
  background: rgb(255 255 255);
  font-size: 18px;
  color: #000;
  text-align: center;
  border: 1px solid #dee2e6;
}
.count_minus, .count_plus {
  cursor: pointer;
  padding: 0 14px;
}
.cart-item .cart-item-label {
  display: block;
  margin-bottom: 10px;
  color: #8c8c8c;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
}
.react-datepicker__input-container input{display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;border-radius: 20px !important;
  height: 48px;}
  .react-datepicker__input-container input:focus  {color: var(--bs-body-color);
    border-color: #cf2227;
    outline: 0;
    box-shadow: none;}
  .count-input.position-relative .translate-middle{pointer-events: none;}
  .m-l-auto {
    margin-left: auto;
}
.menu_item {
  background: #eeeeee;
  border-radius: 6px;
  padding: 5px 10px;
  margin: 5px;
  border: 1px solid #dee2e6;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  width: auto;
  min-height: 80px;
  text-align: center;
  width: 48%;
  height: 80px;
  text-align: center;
}
.modal-footer > .btn2, .btn4 {
  background-color: #ec1d25;
  border-radius: 10px;
  padding: 15px 20px;
  opacity: 0.9;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  border: none;
}
.modal-footer .btn2:hover, .modal-footer .btn4:hover {
  background-color: rgb(46 154 63);
  color: white;
}
.flex-1{flex: 1 !important;}
.card_total_price{font-weight: 600;font-size: 20px;margin: 0 0 15px;}
address{text-align: left;}
.extra_item span{font-size: 14px;}
.extra_items_block .extra_item{margin: 10px 0;}
.sub_total{height: 30px;display: block;;}
/* .total_amt{text-align: right;margin-right: 30px;} */
.search-form{width: 300px;}
.card.widget-stat button{font-weight: 600;}

.store-info div {
  font-size: 1.2em;
}

.store-info div.company-name {
  font-size: 1.5em;
  font-weight: bold;
}

table.order-info td {
  padding: 2px 4px 2px 4px;
}

table.order-info tr td.label {
  font-weight: bold;
  text-align: right;
  border-right: solid 1px #c0c0c0;
}

table.order-info tr td.label.first {}

table.order-info tr td.label.last {}

table.line-items {
  margin-top: 0.1in;
  padding: 0.1in 0in 0.1in 0in;
}

table.line-items th {
  padding: 2px;
}

table.footer {
  border-top: solid 1px #707070;
}

table.footer td.label {
  font-weight: bold;
  text-align: right;
}

td.notes {
  padding: 0.1in;
  font-style: italic;
}

.barcode {
  font-family: "Free 3 of 9 Extended";
  font-size: 48pt;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Error 404
--------------------------------------------------------------*/
.error-404 {
  padding: 30px;
}

.error-404 h1 {
  font-size: 180px;
  font-weight: 700;
  color: #4154f1;
  margin-bottom: 0;
  line-height: 150px;
}

.error-404 h2 {
  font-size: 24px;
  font-weight: 700;
  color: #012970;
  margin-bottom: 30px;
}

.error-404 .btn {
  background: #51678f;
  color: #fff;
  padding: 8px 30px;
}

.error-404 .btn:hover {
  background: #3e4f6f;
}

@media (min-width: 992px) {
  .error-404 img {
    max-width: 50%;
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  padding: 20px 0;
  font-size: 14px;
  transition: all 0.3s;
  border-top: 1px solid #cddfff;
}

.footer .copyright {
  text-align: center;
  color: #012970;
}

.footer .credits {
  padding-top: 5px;
  text-align: center;
  font-size: 13px;
  color: #012970;
}

/* common styles */
.brand-list-content {
  display: flex;
  justify-content: space-evenly;
}

a {
  cursor: pointer;
}
table > thead > tr > th {
  background-color: #ffe2e8  ;
}

table i {
  cursor: pointer;font-size: 14px;line-height: 24px;
}
 .table tbody tr td{border: none;        font-size: .875rem;
  padding: .5rem .9375rem;line-height: 36px;}
  /* table, table>td, table>th {    background-color: #f2f4fa;} */
  .react-bootstrap-table table{    border: 1px solid #ffe2e8;}
  .title_above{background: #3b9f70;padding: 15px 10px;border-radius: 8px;color: var(--white-color) !important;}
  .form-check-input[type=checkbox]{background-color: #f3f4f9;}
  .form_label{font-size: 18px;font-weight: 600;margin: 0;margin-top: 5px;}
  .card_new.card{padding-left: 0;padding-right: 0;overflow: hidden;}
  .card_new .card-header{background: #3b9f70;color: #fff;}
.today_menu span{min-width: 160px;margin:10px;}
.card .text-sm{font-size: 14px;color: #000;}
.card-title.order_d_title{background: #2e9a3f;
  border-radius: 1.75rem 1.75rem 0 0;
  margin: 0 !important;
  padding: 10px;
  color: var(--white-color);}
.orders li:last-child:after{background: none;}
/* .sd{background:rgba(253, 62, 100, 0.15)} */
.card_sub_title_bg{background:rgba(253, 62, 100, 0.15);padding: 10px 20px;border-radius: 10px;}
@media (max-width: 900px) {
  .login-form .box-skew .authincation-content{    position: static;
    width: 100%;
    transform: none;
    padding: 10px;padding-top: 20px;}
    .login-form{position: absolute;overflow: hidden;}
    .login-form .box-skew, .login-form .box-skew1{    min-height: inherit;
      height: inherit;}
      .login-form .box-skew, .login-form .box-skew1{    min-height: inherit;
        height: inherit;}
        .table_flex{flex-direction: column;}
    .card-body{padding-left: 0 !important;padding-right: 0 !important;}
    .coin-tabs{padding: 0;}
    .container-fluid .mx-5 {
      margin-right: 0 !important;
      margin-left:0 !important;
  }
  .cart-item{overflow: auto;}
}
.selected-date {
  background-color: #2196f3 !important;
  color: white !important;
  border-radius: 50%;
}